import Swiper, {Autoplay, EffectCoverflow, EffectFade, Navigation, Pagination} from 'swiper';
import GLightbox from "glightbox";
import {Map, Marker, NavigationControl, Popup} from 'maplibre-gl';


document.addEventListener("DOMContentLoaded", function (event) {

    // ## Initialize MenuButton ##
    const menu_button = document.querySelector('[data-behaviour="toggle-menu"]');
    menu_button.addEventListener('click', () => {
        document.body.classList.toggle('body--show');
    })

    // ## Initialize Lightbox ##
    const lightbox = GLightbox({
        selector: '[data-gallery="gallery"]',
        touchNavigation: true,
        loop: true,
    });

    // ## Initialize Swiper ##
    const sliders = document.querySelectorAll('.swiper');
    sliders.forEach(function (slider) {
        const autoSwiper = slider.classList.contains('swiper--auto');
        const swiper = new Swiper(slider, {
            // configure Swiper to use modules
            modules: [Pagination, Navigation, Autoplay, EffectFade],
            effect: 'slide',
            fadeEffect: {
                crossFade: true
            },
            direction: 'horizontal',
            loop: autoSwiper,

            autoplay: autoSwiper ? {
                delay: 5000,
            } : false,

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    });

    // ## Datalist Toggle ##
    const datalist = document.querySelector('.section--datalist');
    if(datalist) {
        const datalist_list = datalist.querySelector('[data-listtype="list"]');
        const datalist_map = datalist.querySelector('[data-listtype="map"]');
        const datalist__toggles = datalist.querySelectorAll('[data-behaviour="datalist-typetoggle"]');

        datalist__toggles.forEach(function (toggle) {
            toggle.addEventListener('click', () => {
                datalist_list.classList.toggle('hidden');
                datalist_map.classList.toggle('hidden');

                datalist__toggles.forEach(function (toggle) {
                    toggle.classList.toggle('selected');
                })
            })
        })
    }

    // ## Backbutton ##
    const backbuttons = document.querySelectorAll('[data-behaviour="backbutton"]');
    backbuttons.forEach(function (button) {
        button.addEventListener('click', () => {
            window.history.back();
        })
    })

    // ## Accordeon ##
    const accordeonItems = document.querySelectorAll('[data-behaviour="accordeon-item"]');
    if(accordeonItems) {
        accordeonItems.forEach(function (item) {
            const accordeontitle = item.querySelector('.accordeon_item_title');
            const accordeonContent = item.querySelector('.accordeon_item_content');
            accordeonContent.style.maxHeight = 0 + "px";

            accordeontitle.addEventListener('click', () => {
                item.classList.toggle('active');

                if(item.classList.contains('active')) {
                    accordeonContent.style.maxHeight = accordeonContent.scrollHeight + "px";
                } else {
                    accordeonContent.style.maxHeight = 0 + "px";
                }
            })
        })
    }

    // ## Maps ##
    const maps = document.querySelectorAll('[data-behaviour="map"]');
    if(maps) {
        const api_key = '8d8bf301d9d530613f96f2c42b47551a';
        maps.forEach(function (mapElement) {
            const center = mapElement.getAttribute('data-center');
            const map = new Map({
                container: mapElement,
                style: `https://www.goodmaps.de/map-styles/${api_key}`, // style URL
                center: center ? center.split(',') : [9.631947, 54.251622], // starting position [lng, lat]
                zoom: 7, // starting zoom
                cooperativeGestures: true,
            });
            map.addControl(new NavigationControl());

            const dataElement = mapElement.querySelector('[data-behaviour="map-locations"]');
            const locations = JSON.parse(dataElement.textContent);

            locations.forEach(function (location) {
                let locationLink = "#";
                let description = "";
                const popup = new Popup({offset: 25})

                if(location.Description == null){
                    description = "";
                } else {
                    description = '<p class="map_marker_description">' + location.Description + '</p>';
                }

                if(location.Link == null){
                    locationLink = "#";
                    popup.setHTML(
                        `<h4 class="map_marker_title">${location.Title}</h4>` + description
                    );
                } else {
                    locationLink = location.Link;

                    popup.setHTML(
                        `<h4 class="map_marker_title">${location.Title}</h4>` + description + `<a class="map_marker_link link--button" href="` + locationLink + `">Mehr Informationen</a>`
                    );
                }
                
                const marker = new Marker()
                    .setLngLat([location.Longitude, location.Latitude])
                    .setPopup(popup)
                    .addTo(map);
            });

            const mapjumpers = mapElement.querySelectorAll('[data-behaviour="map-jumper"]');
            if(mapjumpers){
                mapjumpers.forEach(function (mapjumper) {
                    mapjumper.addEventListener('click', () => {
                        const targetLongitude = mapjumper.getAttribute('data-longitude');
                        const targetLatitude = mapjumper.getAttribute('data-latitude');
                        map.jumpTo({center: [targetLongitude, targetLatitude], zoom: 15});
                    })
                })
            }

            const maplisttoggle = mapElement.parentElement.querySelector('[data-behaviour="map-list-toggle"]');
            if(maplisttoggle) {
                maplisttoggle.addEventListener('click', () => {
                    mapElement.parentNode.classList.toggle('show--list');
                })
            }
        })
    }

    const districtSelector = document.querySelector('[data-behaviour="district-selector"]');
    if(districtSelector) {
        districtSelector.addEventListener('change', () => {
            window.location.href = districtSelector.value;
        }
    )}
});

